import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";
import CenterIcon from "./CenterIcon";
import { Link } from "react-router-dom";

const SectionHead = ({
    className = "bg-orange-800 text-slate-100",
    title = "الكورسات",
    year = null,
}) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        let { percentage: percent } = getScrollingPercentage(
            scrolling,
            yearsHeader.getBoundingClientRect().top + scrolling,
            (windowHeight + yearsHeader.style.height) / 2
        );
        setPercentage(percent);
    }, [scrolling]);

    const telegramLinks = {
        1: "https://t.me/+8rO0RA7dh0IxNzI0",
        2: "https://t.me/+4__M_WX1n3szN2M0",
        3: "https://t.me/+T1MfN-BihvZkYjhk",
    };
    const facebookLinks = {
        1: "https://www.facebook.com/share/g/yDqUfNDTHS57hszZ/?mibextid=A7sQZp",
        2: "https://www.facebook.com/share/g/B9geAWUctXVsaXwC/?mibextid=A7sQZp",
        3: "https://www.facebook.com/share/g/ewZHPS8yypZnNpe2/?mibextid=A7sQZp",
    };
    return (
        <div className={`home-years__head ${className}`}>
            <div className="relative pt-10 px-10 overflow-hidden">
                <div className="home-years__title font-big py-5 font-w-bold relative">
                    <div
                        className="home-years-title-bg__container"
                        style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                    >
                        <div className="home-years-title__bg font-bigmax text-slate-100">
                            {title}
                        </div>
                    </div>
                    <div className="home-years-title__content relative z-10">{title}</div>
                    {year && (
                        <div className="flex flex-col space-y-5 relative z-50">
                            <a
                                href={telegramLinks[year]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg flex space-x-reverse space-x-4"
                            >
                                <CenterIcon
                                    className={`text-slate-90 animate-pulse font-h1 text-cyan-600 transition duration-200`}
                                    icon="logos:telegram"
                                />
                                <span className="text-[#C4470F]">أذهب لتيليجرام</span>
                            </a>
                            <a
                                href={facebookLinks[year]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-lg flex space-x-reverse space-x-4"
                            >
                                <CenterIcon
                                    className={`text-slate-90 animate-pulse font-h1 text-cyan-600 transition duration-200`}
                                    icon="logos:facebook"
                                />
                                <span className="text-[#0f70c4]">أذهب لفيسبوك</span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
            {/* <Waves className="fill-primary-container transform translate-y-1/4 scale-y-75 -mt-10" /> */}
            <Waves className="fill-primary-container" />
        </div>
    );
};

export default SectionHead;

import React, { useEffect, useState } from "react";

import { ScrollingProvider } from "../../../context/ScrollingContext";

import SectionHead from "../../../components/ui/SectionHead";

import Container from "../../../components/ui/Container";
// import year1 from "../../../../assets/year-2.jpeg";
import Waves from "../../../components/ui/Waves";
import Categorie from "./Categorie";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import LoadingIcon from "./../../../components/ui/LoadingIcon";
import { isCategories } from "../../../services/defaultSettings";
import CoursesWithCategories from "./CoursesWithCategories";

const CoursesSection = ({ title = false, year = 1 }) => {
    // const { token } = useContext(AuthContext);

    // const [categories, setCategories] = useState([]);

    // const getCategories = async () => {
    //     const token = auth.getToken();
    //     const config = auth.getAuthConfig(token);
    //     const { data } = await http.get("/api/categories_info", config);
    //     setCategories(data);
    // };

    // useEffect(() => {
    //     if (isCategories && year == 3) {
    //         getCategories();
    //     }
    // }, []);

    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <ScrollingProvider>
                <SectionHead
                    className=""
                    isWave={false}
                    title={title ? title : "السنوات الدراسية"}
                    year={year}
                />
            </ScrollingProvider>

            <Container>
                <CoursesWithCategories
                    titleFirst="كورسات"
                    titleLast="حديثه"
                    api={`/api/sellables/year/${year}`}
                />
                {/* {isCategories && year == 3 ? (
                    <>
                        {categories.length > 0 ? (
                            categories.map((c) =>
                                c.courses.length ? (
                                    <Categorie
                                        key={c.id}
                                        id={c.id}
                                        count={15}
                                        titleFirst={c.titleFirst}
                                        titleLast={c.titleLast}
                                    />
                                ) : null
                            )
                        ) : (
                            <div className="text-center">
                                <LoadingIcon className="text-4xl text-blue-700 mt-20 mb-5" /> <br />
                                <span className="text-xl">جاري تحميل الكورسات</span>
                            </div>
                        )}
                    </>
                ) : (
                    <Categorie
                        titleFirst="أحدث"
                        titleLast="الكورسات"
                        api={`/api/sellables/year/${year}`}
                    />
                )} */}
            </Container>
            <Waves className="fill-orange-800" />
            <div className="py-16 bg-orange-800"></div>
        </div>
    );
};

export default CoursesSection;
